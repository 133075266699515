import { Component, Input } from "@angular/core";
import { QLSuggestion } from "./s25ql.suggest";
import { S25Const } from "../../util/s25-const";
import { Item } from "../../pojo/Item";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-ql-search-input-suggestions")
@Component({
    selector: "s25-ng-ql-search-input-suggestions",
    template: `
        @switch (suggestion.type) {
            @case ("option") {
                <s25-generic-dropdown
                    [items]="suggestion.data.options"
                    [autoOpen]="true"
                    [alwaysOpen]="true"
                    (chosenChange)="suggestion.onSelect($event)"
                >
                </s25-generic-dropdown>
            }
            @case ("options") {
                <s25-ng-generic-multiselect-dropdown
                    [items]="suggestion.data.options"
                    [autoOpen]="true"
                    [alwaysOpen]="true"
                    [hasDone]="true"
                    (done)="suggestion.onSelect($event)"
                ></s25-ng-generic-multiselect-dropdown>
            }
            @case ("object") {
                <s25-ng-dropdown-search-criteria
                    [type]="suggestion.data.objectType"
                    [autoOpen]="true"
                    [customFilterValue]="suggestion.data.customFilterValue"
                    (chosenChange)="suggestion.onSelect($event)"
                ></s25-ng-dropdown-search-criteria>
            }
            @case ("objects") {
                <s25-ng-dropdown-multi-search-criteria
                    [type]="suggestion.data.objectType"
                    [autoOpen]="true"
                    [staticItems]="
                        suggestion.data.objectType === 'contacts' && [{ itemId: 0, itemName: '', favorite: 'T' }]
                    "
                    [hasDone]="true"
                    (done)="suggestion.onSelect($event)"
                ></s25-ng-dropdown-multi-search-criteria>
            }
            @case ("search") {
                <s25-ng-search-dropdown
                    [hasFav]="true"
                    [itemTypeId]="suggestion.data.searchType"
                    [allowNonQueryId]="false"
                    [searchEnabled]="true"
                    [autoOpen]="true"
                    (chosenChange)="suggestion.onSelect($event)"
                ></s25-ng-search-dropdown>
            }
            @case ("attribute") {
                <s25-custom-attr-dropdown-adv-search
                    [itemTypeId]="type"
                    (queryParamsChange)="suggestion.onSelect($event)"
                ></s25-custom-attr-dropdown-adv-search>
            }
            @case ("contactRole") {
                <s25-ng-dropdown-multi-contacts
                    [type]="type"
                    [autoOpen]="true"
                    [alwaysOpen]="true"
                    [hasDone]="true"
                    (done)="suggestion.onSelect($event)"
                ></s25-ng-dropdown-multi-contacts>
            }
            @case ("relationship") {
                <s25-ng-dropdown-multi-relationships
                    [type]="$any(type)"
                    [autoOpen]="true"
                    [alwaysOpen]="true"
                    [placeholder]="'Search ' + (S25Const.itemId2Name[type] || '')"
                    [hasDone]="true"
                    (done)="suggestion.onSelect($event)"
                ></s25-ng-dropdown-multi-relationships>
            }
            @case ("address") {
                <s25-ng-ql-suggest-address
                    (done)="suggestion.onSelect($event)"
                    (click)="$event.stopPropagation()"
                ></s25-ng-ql-suggest-address>
            }
        }
    `,
})
export class S25qlSearchInputSuggestionsComponent {
    @Input() type: Item.Id;
    @Input() suggestion: QLSuggestion["suggestion"];

    S25Const = S25Const;
}
