import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { S25QLConst } from "./s25ql.const";
import { Tokenizer } from "./s25ql.tokenizer";
import { AdvancedSearchUtil } from "../advanced-search/advanced-search-util";
import { DropDownItem } from "../../pojo/DropDownItem";
import { AdvancedStep } from "./s25ql.search.advanced.criteria.component";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";
import { ContactService } from "../../services/contact.service";
import { SearchCriteriaType } from "../../pojo/SearchCriteriaI";
import { Bind } from "../../decorators/bind.decorator";
import { ConfigService } from "../../services/config.service";
import { Item } from "../../pojo/Item";
import Ids = Item.Ids;
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-ql-search-simple-input")
@Component({
    selector: "s25-ng-ql-search-simple-input",
    template: `
        <div *ngIf="isInit" class="criteria">
            <div *ngFor="let criterion of criteria" class="criterion {{ criterion.custom }}">
                <s25-ng-multiselect-search-criteria
                    *ngIf="criterion.searchCriteria"
                    [type]="criterion.searchCriteria"
                    [selectedItems]="criterion.bean.selectedItems"
                    [modelBean]="criterion.bean"
                    [honorMatching]="criterion.bean.showMatching"
                    [useSecurity]="true"
                    [popoverPlacement]="['bottom', 'left', 'right', 'top']"
                    (changed)="onChange()"
                ></s25-ng-multiselect-search-criteria>

                <div *ngIf="criterion.custom" [ngSwitch]="criterion.custom">
                    <label class="customLabel">{{ criterion.custom }}</label>
                    <div *ngSwitchCase="'Capacity'" class="capacity">
                        <div class="capacityColumn">
                            <label>between</label>
                            <input
                                type="number"
                                min="0"
                                [(ngModel)]="criterion.bean.min"
                                class="c-input"
                                (ngModelChange)="onChange()"
                            />
                        </div>
                        <div class="capacityColumn">
                            <label>and</label>
                            <input
                                type="number"
                                min="0"
                                [(ngModel)]="criterion.bean.max"
                                class="c-input"
                                (ngModelChange)="onChange()"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: `
        .criteria {
            display: flex;
            flex-wrap: wrap;
            gap: 1em;
            font-size: 14px;
        }

        .criterion {
            width: 130px;
        }

        .criterion.Capacity {
            width: 170px;
        }

        :host ::ng-deep .s25-multiselect-item-text-col {
            overflow-wrap: anywhere;
        }

        .customLabel {
            color: var(--color-primary);
        }

        .capacity {
            display: flex;
            justify-content: space-between;
        }

        .capacityColumn {
            width: 45%;
        }

        .capacityColumn input {
            width: 100%;
        }
    `,
})
export class S25qlSearchSimpleInputComponent implements OnInit, OnChanges {
    @Input() type: Tokenizer.QLItemTypes;
    @Input() criteria: SimpleSearchCriterion[];

    @Output() criteriaChange = new EventEmitter<any[]>();

    isInit = false;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.type && !changes.type.firstChange) this.ngOnInit();
        if (changes.criteria && !changes.criteria.firstChange) this.ngOnInit();
    }

    ngOnInit() {
        if (!this.criteria) this.setDefaultCriteria();
        this.isInit = true;
    }

    @Bind
    onChange() {
        this.criteriaChange.emit(this.criteria);
    }

    async setDefaultCriteria() {
        const visibleCriteria = new Set((await this.getVisibleCriteria()).map((item) => item.itemId));
        this.criteria = S25QLConst.simpleCriteria[this.type]
            .filter((crit: any) => visibleCriteria.has(crit.id))
            .map((crit: any) => ({
                ...crit,
                bean: {
                    showResult: true,
                    textButton: true,
                    singleSelect: crit.single,
                    showMatching: crit.hasMatch || false,
                },
            }));
        this.changeDetector.detectChanges();
        this.onChange();
    }

    async getVisibleCriteria(): Promise<{ itemId: number }[]> {
        let data: { itemId: number }[];
        if (this.type === Ids.Event) data = await ConfigService.getEvent();
        else if (this.type === Ids.Location) data = await ConfigService.getSpace();
        else if (this.type === Ids.Resource) data = await ConfigService.getResource();
        else if (this.type === Ids.Organization) data = await ConfigService.getOrganization();
        else data = [];
        return data || [];
    }

    getParams() {
        const criteria = (this.criteria ?? [])
            .map((crit) => {
                if (crit.searchCriteria) {
                    if (!crit.bean.selectedItems.length) return;
                    const ids = crit.bean.selectedItems.map((item: any) => item.itemId);
                    const separator = crit.hasMatch && crit.bean.matching === "all" ? "," : "+";
                    return `&${crit.parameter}=${ids.join(separator)}`;
                } else if (crit.custom) {
                    return crit.getParam(crit.bean);
                }
            })
            .filter((crit) => crit);
        return criteria.join("");
    }

    async getModel() {
        const steps: AdvancedStep[] = [];
        for (let criterion of this.criteria) {
            const key = criterion.searchCriteria || criterion.custom;
            const { stepTypeId, subStepTypeId } = S25QLConst.simpleCriteriaStepTypeMap[key];
            const step = AdvancedSearchUtil.getStep(this.type, stepTypeId, subStepTypeId);
            if (criterion.custom) {
                if (criterion.custom === "Capacity") {
                    if (!criterion.bean.min && !criterion.bean.max) continue; // Skip
                    step.step_param[0].min_capacity = criterion.bean.min;
                    step.step_param[0].max_capacity = criterion.bean.max;
                }
            } else {
                if (!criterion.bean.selectedItems.length) continue; // Skip
                step.qualifier = 1;
                if (criterion.hasMatch && criterion.bean.matching === "all") step.qualifier = 2;
                step.step_param = criterion.bean.selectedItems.map(({ itemName, itemId }: DropDownItem) => ({
                    itemName,
                    itemId,
                }));
                if (key === "eventRoles") {
                    step.contact_role_id = step.step_param[0].itemId;
                    const [itemName, itemId] = await Promise.all([
                        ContactService.getCurrentName(),
                        ContactService.getCurrentId(),
                    ]);
                    step.step_param = [{ itemId, itemName }];
                }
            }
            steps.push(step);
        }

        return steps;
    }
}

export type SimpleSearchCriterion = {
    bean: MultiselectModelI & { min?: number; max?: number };
    searchCriteria?: SearchCriteriaType["type"];
    custom?: string;
    single?: boolean;
    hasMatch?: boolean;
    parameter?: string;
    getParam?: (bean: MultiselectModelI & { min?: number; max?: number }) => string;
};
