import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { QLTerm } from "./s25ql.const";
import { S25QLTokenizer } from "./s25ql.tokenizer";
import { Item } from "../../pojo/Item";
import { DropDownItem } from "../../pojo/DropDownItem";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { SearchCriteriaType } from "../../pojo/SearchCriteriaI";
import { DropdownApi } from "../s25-dropdown/dropdown.api";
import { S25QLSerializer } from "./s25ql.serializer";
import { S25QLSuggest } from "./s25ql.suggest";
import { UserprefService } from "../../services/userpref.service";
import Ids = Item.Ids;

@TypeManagerDecorator("s25-ng-ql-search-input")
@Component({
    selector: "s25-ng-ql-search-input",
    template: `
        <div class="searchWrapper">
            <div class="searchInputWrapper">
                <textarea
                    #searchInput
                    class="searchInput"
                    [placeholder]="placeholder"
                    [(ngModel)]="value"
                    [disabled]="disabled"
                    (ngModelChange)="onInputChange($event)"
                    (click)="setTextareaHeight(); suggest(); $event.stopPropagation()"
                    (keydown)="onKeydown($event)"
                    (keyup)="onKeyup($event)"
                ></textarea>
                @if (!disabled) {
                    <button class="clear" (click)="clearInput(); focusInput()">
                        <s25-ng-icon [type]="'close'" [label]="'Clear Text'" [size]="16"></s25-ng-icon>
                    </button>
                }
            </div>
            @if (loggedIn && !disabled) {
                <s25-ng-help-button [topic]="'series_ql'"></s25-ng-help-button>
            }
        </div>

        @if (suggestion && type !== 3) {
            <div class="suggestWrapper" (click)="$event.stopPropagation()">
                <s25-ng-ql-search-input-suggestions
                    [type]="$any(type)"
                    [suggestion]="suggestion"
                ></s25-ng-ql-search-input-suggestions>
            </div>
        }

        @if (loggedIn && type !== 3 && !disabled) {
            <div class="hint">
                <span class="cn-alert__icon cn-icon" name="alert--info">
                    <s25-ng-icon [type]="'info'" [label]="'Informational Alert'" [size]="'1.5em'"></s25-ng-icon>
                    Hint! Type :: to use SeriesQL
                </span>
            </div>
        }
    `,
    styles: `
        .searchWrapper {
            display: flex;
            gap: 0.5em;
        }

        .searchInputWrapper {
            position: relative;
            flex-grow: 1;
        }

        textarea {
            display: block;
        }

        .searchInput {
            width: 100%;
            padding: 5px 2em 2px 0.75em;
            height: 2em;
            resize: none;
        }

        .searchInput:focus {
            box-shadow: 0 0 0 1px var(--color-primary);
            outline: 0;
        }

        .clear {
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 50%;
            color: rgba(0, 0, 0, 0.8);
            background-color: transparent;
            border: 0;
            height: 1.5em;
            aspect-ratio: 1;
            cursor: pointer;
            margin: 0.25em;
            padding: 0;
        }

        .clear:active {
            background-color: #f0f1f2;
        }

        .clear:focus {
            box-shadow: 0 0 0 2px var(--color-primary);
            color: var(--color-primary);
            outline: 0;
        }

        .clear:hover {
            background-color: #fafaf9;
            color: var(--color-primary);
        }

        ::ng-deep .nm-party--on s25-ng-ql-search-input button.clear {
            color: rgba(255, 255, 255, 0.5);
        }

        ::ng-deep .nm-party--on s25-ng-ql-search-input button.clear:hover {
            background-color: rgba(0, 0, 0, 0.2);
        }

        s25-ng-help-button {
            color: #2573a7;
            margin: auto 0;
        }

        .suggestWrapper {
            width: 24em;
            position: absolute;
        }

        .hint {
            font-size: 0.75em;
            padding: 0.5em 0;
        }
    `,
})
export class S25qlSearchInputComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() value: string = "";
    @Input() placeholder: string = "Search";
    @Input() hasHelpButton: boolean = true;
    @Input() type:
        | Item.Ids.Event
        | Item.Ids.Organization
        | Item.Ids.Location
        | Item.Ids.Resource
        | Item.Ids.Task
        | Ids.Contact;
    @Input() disabled: boolean = false;

    @Output() valueChange = new EventEmitter<string>();
    @Output() search = new EventEmitter<void>();
    @Output() errorMessage = new EventEmitter<string>();

    @ViewChild("searchInput") inputElement: ElementRef;

    loggedIn = false;
    suggestion: {
        type: QLTerm.Suggest;
        data: {
            input: string;
            options?: DropDownItem[];
            objectType?: SearchCriteriaType["type"];
            chosenObjects?: DropDownItem[]; // For now s25-ng-dropdown-multi-search-criteria does not support picking chosen objects
            searchType?: Item.Ids;
        };
        inDropdown?: boolean; // To indicate whether the user used arrow keys to move into dropdown when suggesting a conjunction
        onSelect?: (...args: unknown[]) => void;
        rejectSuggestion: (reason?: any) => void;
    };

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {
        window.QLTokenizer = S25QLTokenizer;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.type || changes.value) {
            this.setTextareaHeight();
        }
    }

    async ngOnInit() {
        // Listen to "Escape" key in capturing phase because dropdowns stop propagation in bubbling phase
        // Reset suggestions when escape is pressed
        this.elementRef.nativeElement.addEventListener(
            "keydown",
            (event: KeyboardEvent) => event.key === "Escape" && this.resetSuggestion(true),
            true,
        );

        this.loggedIn = await UserprefService.getLoggedIn();
    }

    ngAfterViewInit() {
        this.setTextareaHeight();
    }

    onInputChange(value: string) {
        this.valueChange.emit(value);
        this.setTextareaHeight();
        this.suggest();
    }

    clearInput() {
        this.setValue("");
    }

    setValue(value: string) {
        this.value = value;
        this.valueChange.emit(value);
        this.setTextareaHeight();
        this.changeDetector.detectChanges();
    }

    focusInput() {
        this.inputElement?.nativeElement.focus();
    }

    setTextareaHeight() {
        const elem = this.inputElement?.nativeElement;
        if (!elem) return;
        elem.value = this.value; // For unknown reasons this is needed when clearing the input
        elem.style.removeProperty("height");
        elem.style.height = `${elem.scrollHeight + 2}px`;
    }

    async suggest(): Promise<void> {
        this.resetSuggestion();
        if (this.type === Ids.Contact) return; // Contact search does not support SeriesQL

        if (!/^[\s\n\t]*::/.test(this.value)) return; // Don't do anything to pure queries

        const caretIndex = this.inputElement.nativeElement.selectionStart;
        if (caretIndex !== this.inputElement.nativeElement.selectionEnd) return; // User is selecting

        // If caret is before "::", then do not suggest
        if (!/^[\s\n\t]*::/.test(this.value.slice(0, caretIndex))) return;

        const { suggestion, promise, query } = S25QLSuggest.suggest(this.type, this.value, caretIndex);
        this.suggestion = suggestion;
        if (!(await promise)) return; // No choice was made

        const serialized = S25QLSerializer.serialize(query);
        await this.setQuery(serialized.serial, serialized.caret);
        return this.suggest(); // Once we have completed a suggestion, see if we need to suggest the next entry
    }

    async setQuery(query: string, index?: number) {
        this.setValue(`::${query}`);
        this.inputElement.nativeElement.focus();
        await S25Util.delay(0); // Needed to let selectionStart update
        this.setTextareaHeight();
        this.inputElement.nativeElement.selectionStart = (index || query.length) + 2;
        this.inputElement.nativeElement.selectionEnd = (index || query.length) + 2;
        await S25Util.delay(0); // Needed to let selectionStart update
    }

    @HostListener("document:click")
    resetSuggestion(focus = false) {
        this.suggestion?.rejectSuggestion(); // Reject suggestion promise since it's never going to be resolved
        this.suggestion = null;
        if (focus) this.inputElement.nativeElement.focus();
    }

    onKeydown(event: KeyboardEvent) {
        switch (event.key) {
            case "ArrowUp":
            case "ArrowDown":
                // If we are currently suggesting and up or down arrow key is pressed, move selection rather than caret
                if (!this.suggestion) return;
                event.stopPropagation();
                event.preventDefault();
                return DropdownApi.arrow(this.elementRef.nativeElement, event.key === "ArrowUp" ? "up" : "down");
            case "Enter":
                if (event.shiftKey) return;
                event.preventDefault();
                event.stopPropagation();
                if (this.suggestion?.type.startsWith("option")) {
                    // If we are suggesting options and have not used arrow keys to move into dropdown, run search
                    if (!this.suggestion.inDropdown) return this.runSearch();
                    // If we are suggesting options and have moved into dropdown, select the item
                    if (this.suggestion.inDropdown) return DropdownApi.enterSelect(this.elementRef.nativeElement);
                } else return this.runSearch();
                return;
            case "Escape":
                // If escape is pressed, reset suggestions
                return this.resetSuggestion(true);
        }

        this.clearErrorMessage();
    }

    onKeyup(event: KeyboardEvent) {
        // If we are currently suggesting and up or down arrow key is pressed, don't suggest
        if (this.suggestion && (event.key === "ArrowUp" || event.key === "ArrowDown")) {
            if (event.key === "ArrowDown") this.suggestion.inDropdown = true; // Once you've moved into the suggestion
            return;
        }
        // If key is escape, don't suggest
        if (event.key === "Escape") return;
        // If key is enter, don't suggest
        if (event.key === "Enter") return;

        // Make suggestions while typing
        this.suggest();
    }

    runSearch() {
        this.resetSuggestion();
        this.search.emit();

        if (/^[\s\n\t]*::/.test(this.value) && this.type !== Ids.Contact) {
            // Refresh input to reflect what was searched
            const serialized = S25QLSerializer.serialize(S25QLTokenizer.tokenizer(this.value, this.type));
            this.setQuery(serialized.serial, serialized.caret);
        }
    }

    clearErrorMessage() {
        this.errorMessage.emit(null);
    }
}
