import { Tokenizer } from "./s25ql.tokenizer";
import Query = Tokenizer.Query;
import Conjunction = Tokenizer.Conjunction;
import Clause = Tokenizer.Clause;
import Value = Tokenizer.Value;
import MathValue = Tokenizer.MathValue;
import ListValue = Tokenizer.ListValue;
import EmbeddedValue = Tokenizer.EmbeddedValue;
import CustomAttributeValue = Tokenizer.CustomAttributeValue;
import RangeValue = Tokenizer.RangeValue;
import DateValue = Tokenizer.DateValue;

function serializeQuery(query: Query, enclosed: boolean = false): { serial: string; caret: number } {
    const listSeparator = " ";
    const values = query.value.map((token) => {
        if (token.type === "query") return serializeQuery(token, true);
        else if (token.type === "clause") return serializeClause(token);
        else if (token.type === "conjunction") return serializeConjunction(token);
        else return { serial: "", caret: 0 };
    });

    let caret = 0;
    const caretTokenIndex = values.findIndex((value) => value.caret); // Find index of caret token
    if (caretTokenIndex >= 0) {
        caret = enclosed ? 1 : 0; // 1 for opening parenthesis
        caret += values.slice(0, caretTokenIndex).reduce((sum, value) => sum + value.serial.length, 0); // String length before caret token
        caret += listSeparator.length * caretTokenIndex; // For list separators
        caret += values[caretTokenIndex].caret; // Caret position in token
    }

    const inner = values.map((value) => value.serial).join(listSeparator);
    const serial = enclosed ? `(${inner}${query.closed ? ")" : ""}` : inner;
    if (query.hasCaret) caret = serial.length;

    return { serial, caret };
}

function serializeConjunction(conjunction: Conjunction): { serial: string; caret: number } {
    const serial = conjunction.value;
    const caret = conjunction.hasCaret ? serial.length : 0;
    return { serial, caret };
}

function serializeClause({ value: { term, operator, value }, hasCaret }: Clause): { serial: string; caret: number } {
    const serializedValue = serializeValue(value);

    const serial = `${term?.value || ""} ${operator?.value || ""} ${serializedValue.serial}`;
    const termCaret = term?.value?.length || 0;
    const operatorCaret = termCaret + 1 + (operator?.value?.length || 0);
    const valueCaret = operatorCaret + 1 + serializedValue.caret;
    const caret = hasCaret
        ? serial.length
        : term?.hasCaret
          ? termCaret
          : operator?.hasCaret
            ? operatorCaret
            : serializedValue.caret
              ? valueCaret
              : 0;

    return { serial, caret };
}

function serializeValue(value: Value | Tokenizer.Operator): { serial: string; caret: number } {
    if (!value) return { serial: "", caret: 0 };

    let serialized: { serial: string; caret: number };
    if (value.type === "string") serialized = { serial: `"${value.value}"`, caret: 0 };
    else if (["number", "boolean", "date", "time", "operator", "keyword"].includes(value.type))
        serialized = { serial: String(value.value), caret: 0 };
    else if (value.type === "math") serialized = serializeMathValue(value);
    else if (value.type === "list") serialized = serializeListValue(value);
    else if (value.type === "embedded") serialized = serializeEmbeddedValue(value);
    else if (value.type === "customAttribute") serialized = serializeCustomAttributeValue(value);
    else if (value.type === "range") serialized = serializeRangeValue(value);

    const serial = serialized.serial;
    const caret = value.hasCaret ? serial.length : serialized.caret;
    return { serial, caret };
}

function serializeMathValue(math: MathValue<DateValue>): { serial: string; caret: number } {
    const pre = math.wrapped ? "(" : "";
    const values = math.value.map((value) => serializeValue(value));
    const post = math.wrapped ? ")" : "";

    let caret = 0;
    const caretTokenIndex = values.findIndex((value) => value.caret); // Find index of caret token
    if (caretTokenIndex >= 0) {
        caret += values.slice(0, caretTokenIndex).reduce((sum, value) => sum + value.serial.length, 0); // String length before caret token
        caret += caretTokenIndex; // For " " separators
        caret += values[caretTokenIndex].caret; // Caret position in token
    }

    const valuesSerial = values.map((value) => value.serial).join(" ");
    const serial = `${pre}${valuesSerial}${post}`;
    if (math.hasCaret) caret = serial.length;
    return { serial, caret };
}

function serializeListValue(list: ListValue<any>): { serial: string; caret: number } {
    const listSeparator = ", ";
    const values = list.value.map((value) => serializeValue(value));

    let caret = 0;
    const caretTokenIndex = values.findIndex((value) => value.caret); // Find index of caret token
    if (caretTokenIndex >= 0) {
        caret = 1; // 1 for opening parenthesis
        caret += values.slice(0, caretTokenIndex).reduce((sum, value) => sum + value.serial.length, 0); // String length before caret token
        caret += listSeparator.length * caretTokenIndex; // For list separators
        caret += values[caretTokenIndex].caret; // Caret position in token
    }

    const serial = `(${values.map((value) => value.serial).join(listSeparator)})`;
    if (list.hasCaret) caret = serial.length + 2; // +2 for parentheses

    return { serial, caret };
}

function serializeEmbeddedValue(embedded: EmbeddedValue): { serial: string; caret: number } {
    const { embeddedType, embeddedValue } = embedded.value;
    const values = embedded.value.values.map((value) => serializeValue(value).serial).join(", ");

    const serial = `("${embeddedType || ""}:${embeddedValue ?? ""}", ${values})`;
    const caret = embedded.hasCaret ? serial.length : 0;
    return { serial, caret };
}

function serializeCustomAttributeValue(attribute: CustomAttributeValue): { serial: string; caret: number } {
    const { name, operator, type, value } = attribute.value;
    const nameQuote = name.includes('"') ? "'" : '"';
    const valueQuote = String(value).includes('"') ? "'" : '"';
    const valueSerial = value ? `, ${valueQuote}${value}${valueQuote}` : "";

    const serial = `(${nameQuote}${name}${nameQuote}, "${operator}", "${type}"${valueSerial})`;
    const caret = attribute.hasCaret ? serial.length : 0;
    return { serial, caret };
}

function serializeRangeValue(range: RangeValue<any>): { serial: string; caret: number } {
    const start = serializeValue(range.value.start);
    const end = serializeValue(range.value.end);

    const serial = `${start.serial} and ${end.serial}`;
    const startCaret = start.caret;
    const endCaret = start.serial.length + " and ".length + end.caret;
    const caret = range.hasCaret ? serial.length : start.caret ? startCaret : end.caret ? endCaret : 0;
    return { serial, caret };
}

export const S25QLSerializer = {
    serialize: (query: Query) => serializeQuery(query),
};
